import firebase from "firebase/compat/app";
import "firebase/compat/database";

import {
  type AsyncTaskBase,
  type LivePreviewCropTask,
  type TaskCreator,
  log,
} from "shared/client";
import {
  DashboardEvent,
  type Event,
  type TrackStatusFunction,
  trackStatus,
} from "utils/telemetry";

// we have created these little utility types to allow us to separate the
// "base" of the async task properties and the properties of the TaskType
// the `Task` type allow us to unify these two types together
type TaskData<TaskType> = Omit<TaskType, keyof AsyncTaskBase>;
type Task<TaskType> = TaskData<TaskType> & AsyncTaskBase;

interface TaskFactoryOptions {
  analyticsEvent: Event;
  skipAttemptEvent?: boolean;
  maxTaskDuration: number; // in seconds
  trackStatus?: TrackStatusFunction;
}

export interface TaskOptions {
  onTaskID?: (taskID: string) => void;
}

export type TaskResult =
  | { status: "success" }
  | { status: "skipped" }
  | { status: "error"; reason?: string };

export async function monitorTask<TaskType extends AsyncTaskBase>(
  bucket: string,
  taskID: string,
  version: number,
  options: TaskFactoryOptions,
): Promise<TaskResult> {
  const ref = await firebase.database?.().ref(`${bucket}/${taskID}`);

  if (!ref) {
    return { status: "error", reason: "database" };
  }

  const currentSnapshot = await ref.get();
  const initialTask: TaskType = currentSnapshot.val();

  if (initialTask.version !== version) return { status: "error" };
  else if (initialTask.completed) return { status: "success" };

  const queuedAt = initialTask.queuedAt;
  const timeElapsed = Date.now() - queuedAt;
  const timeoutTime = options.maxTaskDuration * 1000 - timeElapsed;

  return new Promise((resolve) => {
    const timeout = setTimeout(async () => {
      ref.off();

      const totalTime = Date.now() - queuedAt;

      options.trackStatus?.(options.analyticsEvent, "error", {
        time: totalTime,
        timeout: true,
      });

      let updatedTask: TaskType | null;

      try {
        const snapshot = await ref.get();
        updatedTask = snapshot.val();
      } catch {
        updatedTask = null;
      }

      log.error("task-client", "task-timeout", {
        bucket,
        taskKey: ref.key,
        originalTask: initialTask,
        updatedTask,
        time: totalTime,
      });

      resolve({ status: "error", reason: "timeout" });
    }, timeoutTime);

    ref.on(
      "value",
      (snapshot) => {
        const updatedTask: TaskType = snapshot.val();

        if (!updatedTask.completed) return;

        clearTimeout(timeout);

        const totalTime = Date.now() - queuedAt;

        if (updatedTask.status === "success") {
          options.trackStatus?.(options.analyticsEvent, "success", {
            totalTime,
          });

          resolve({ status: "success" });
        } else if (updatedTask.status === "skipped") {
          options.trackStatus?.(options.analyticsEvent, "skipped", {
            totalTime,
          });

          resolve({ status: "skipped" });
        } else if (updatedTask.status === "error") {
          options.trackStatus?.(options.analyticsEvent, "error", { totalTime });

          log.error("task-client", "task-error", {
            bucket,
            taskKey: ref.key,
            updatedTask,
          });

          resolve({ status: "error", reason: "task" });
        } else {
          options.trackStatus?.(options.analyticsEvent, "error", { totalTime });

          log.exception("task-client", "unknown-error", {
            bucket,
            taskKey: ref.key,
            updatedTask,
          });

          resolve({ status: "error", reason: "unknown" });
        }

        ref.off();
      },
      (error) => {
        clearTimeout(timeout);

        options.trackStatus?.(options.analyticsEvent, "error");

        log.exception("task-client", "database-error", {
          bucket,
          taskKey: ref.key,
          originalTask: initialTask,
          error,
        });

        resolve({ status: "error", reason: "database" });

        ref.off();
      },
    );
  });
}

// this is a way to encapsulate all of the shared logic for dealing with async task
// these are powered by `build-service` and firebase real time db
// there are a lot of different possible errors particularily that this handles
// NOTE: this function should NEVER throw an error
const createAndMonitorTaskFactory = <TaskType extends AsyncTaskBase>(
  bucket: string,
  version: number,
  options: TaskFactoryOptions,
) => {
  return async (
    data: TaskData<TaskType>,
    creator: TaskCreator,
    taskOptions?: TaskOptions,
  ): Promise<TaskResult> => {
    // begin timer for this task to track in analytics
    const startTime = Date.now();

    // create the task payload
    const task: Task<TaskType> = {
      ...data,
      ...creator,

      version,
      status: "queued",
      queuedAt: startTime,
    };

    if (!options.skipAttemptEvent) {
      options.trackStatus?.(options.analyticsEvent, "attempt");
    }

    try {
      const ref = await firebase.database?.().ref(bucket).push();

      if (!ref) {
        return { status: "error", reason: "database" };
      }

      if (ref.key) {
        await ref.set(task);

        taskOptions?.onTaskID?.(ref.key);

        return monitorTask(bucket, ref.key ?? "", version, options);
      } else {
        throw new Error("Ref should have key attached to it.");
      }
    } catch (error) {
      options.trackStatus?.(options.analyticsEvent, "error");

      log.exception("task-client", "function-error", {
        bucket,
        originalTask: task,
        error,
      });

      return { status: "error", reason: "function" };
    }
  };
};

export const createAndMonitorLivePreviewCrop =
  createAndMonitorTaskFactory<LivePreviewCropTask>("lpc", 1, {
    analyticsEvent: DashboardEvent.generateLivePreviewCrops,
    maxTaskDuration: 60,
    trackStatus,
  });
