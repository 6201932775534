import firebase from "firebase/compat/app";
import "firebase/compat/database";
import {
  directoryProfilesBulkImportJobSchema,
  directorySchema,
  siteSchema,
} from "publit-shared/client";
import type { z } from "zod";

function createCollection<T>(path: string, schema: z.ZodSchema<T>) {
  const collection = () => firebase.firestore().collection(path);

  const fakeGet = () => collection().doc("fake").get();

  const parseDocSnapshot = (snapshot: Awaited<ReturnType<typeof fakeGet>>) => {
    return {
      id: snapshot.id,
      data: schema.parse(snapshot.data()),
    };
  };

  return {
    findByID: (id: string) => collection().doc(id).get().then(parseDocSnapshot),
    updateWithID: (id: string, updates: Record<string, unknown>) =>
      collection().doc(id).update(updates),
  };
}

export const clientRecords = {
  directory: createCollection("directories", directorySchema),
  site: createCollection("sites", siteSchema),
  directoryBulkImportJobs: createCollection(
    "directory-bulk-import-jobs",
    directoryProfilesBulkImportJobSchema,
  ),
};
