import { log } from "shared/client";
import { refreshToken } from "./firebase";

const handle = async (res, { path, isRetryAttempt, retry }) => {
  try {
    const json = await res.json();

    if (Object.prototype.hasOwnProperty.call(json, "error")) {
      // if the error code is about an expired firebase token AND this is the first attempt
      // then we will retry the request
      if (json.error.code === "fb-expired-token" && !isRetryAttempt) {
        await refreshToken();
        return retry();
      } else {
        return json;
      }
    }

    return { data: json };
  } catch (error) {
    return {
      error: {
        path,
        message: res.statusText,
        fromWithinHandle: true,
        errorString: error.toString(),
      },
    };
  }
};

// this is meant to be used with SWR
export const fetchSWR = async (path, isRetryAttempt = false) => {
  const res = await fetch(`/api/${path}`, {
    credentials: "same-origin",
  });

  const json = await res.json();

  if (Object.prototype.hasOwnProperty.call(json, "error")) {
    // if the error code is about an expired firebase token AND this is the first attempt
    // then we will retry the request
    if (json.error.code === "fb-expired-token" && !isRetryAttempt) {
      await refreshToken();
      return fetchSWR(path, true);
    } else {
      log.error("api-client", "fetch-swr-error", {
        path,
        isRetryAttempt,
        error: json.error,
      });
      throw json.error;
    }
  }

  return json;
};

// this is meant to be used with SWR
export const sendSWR = async (path, params, isRetryAttempt = false) => {
  const res = await fetch(`/api/${path}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
    credentials: "same-origin",
  });

  const json = await res.json();

  if (Object.prototype.hasOwnProperty.call(json, "error")) {
    // if the error code is about an expired firebase token AND this is the first attempt
    // then we will retry the request
    if (json.error.code === "fb-expired-token" && !isRetryAttempt) {
      await refreshToken();
      return sendSWR(path, params, true);
    } else {
      log.error("api-client", "fetch-swr-error", {
        path,
        isRetryAttempt,
        error: json.error,
      });
      throw json.error;
    }
  }

  return json;
};

export const send = async (path, params, isRetryAttempt = false) => {
  try {
    const res = await fetch(`/api/${path}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
      credentials: "same-origin",
    });

    const result = await handle(res, {
      path,
      isRetryAttempt,
      retry() {
        return send(path, params, true);
      },
    });

    return result;
  } catch (error) {
    log.error("api-client", "network-error", {
      path,
      params,
      isRetryAttempt,
      error,
      errorString: error.toString(),
    });
    return { error: { message: "Server Error." } };
  }
};

export const ensureParamsForSWR = (...args) => {
  return () => {
    const path = args.pop();

    for (const param of args) {
      if (typeof param === "undefined") {
        throw new Error("Request not ready yet");
      }
    }

    return path;
  };
};
