import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/analytics";
import "firebase/compat/storage";

import { log } from "shared/client";
import { AuthEvent, identify, track } from "utils/telemetry";

import { send } from "../api";
import { retry } from "../retry";

// AUTHENTICATION

export const logIn = async (email, password) => {
  try {
    const auth = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);

    identify(auth.user.uid);

    return { success: true };
  } catch (e) {
    let msg;
    if (e.code === "auth/user-not-found" || e.code === "auth/wrong-password") {
      msg = "The email/password you entered were incorrect.";
    } else if (e.code === "auth/invalid-email") {
      msg = "The email you entered was invalid.";
    } else if (e.code === "auth/user-disabled") {
      msg = "Your account has been disabled.";
    } else {
      log.error("fb", "log-in", { error: e.toString() });
      msg =
        "Something went wrong on our end, please contact support@publitdesign.com.";
    }

    return { success: false, errorMessage: msg };
  }
};

export const logInWithToken = async (token) => {
  try {
    await firebase.auth().signInWithCustomToken(token);

    return { success: true };
  } catch (e) {
    console.log("cannot log in with token", e);
    return { success: false };
  }
};

export const signUp = async (name, email, password) => {
  try {
    const auth = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);

    await auth.user.updateProfile({ displayName: name });
    const { uid } = auth.user;

    const createdRes = await retry(async () => {
      const res = await send("auth/created");
      if (res.error) throw new Error(res.error);
      return res;
    });

    identify(uid);

    return {
      success: true,
      hasPendingInvites: createdRes.data?.hasPendingInvites,
    };
  } catch (e) {
    let msg;
    if (e.code === "auth/email-already-in-use") {
      msg = "The email you tried is already in use.";
    } else if (e.code === "auth/invalid-email") {
      msg = "The email you entered is invalid.";
    } else if (e.code === "auth/weak-password") {
      msg = "Please use a stronger password.";
    } else {
      log.error("fb", "sign-up", { error: e.toString() });
      msg =
        "Something went wrong on our end, please contact support@publitdesign.com.";
    }

    return { success: false, errorMessage: msg };
  }
};

export const logOut = async () => {
  await firebase.auth().signOut();
};

export const refreshToken = async () => {
  const user = firebase.auth().currentUser;

  if (user) {
    const token = await user.getIdToken(true);
    return token;
  } else {
    return null;
  }
};

export const resetPassword = async (email) => {
  try {
    const { data } = await sendEmail("reset-password", email);
    return data;
  } catch (e) {
    log.error("fb", "reset-password", { error: e.toString() });
    return { success: false };
  }
};

// this is used in the action handler
export const useFirebaseAuth = () => firebase.auth();

// this is required for changing email or password or deleting account
export const reauthenticate = async (password) => {
  try {
    const user = firebase.auth().currentUser;
    if (!user) return false;

    await user.reauthenticateWithCredential(
      firebase.auth.EmailAuthProvider.credential(user.email, password),
    );

    return true;
  } catch (e) {
    log.error("fb", "reauthenticate", { error: e.toString() });
    return false;
  }
};

export const updateEmail = async (newEmail) => {
  try {
    const user = firebase.auth().currentUser;
    if (!user) return false;

    await user.verifyBeforeUpdateEmail(newEmail);

    track(AuthEvent.changeEmail);
    return true;
  } catch (e) {
    log.error("fb", "update-email", { error: e.toString() });
    return false;
  }
};

export const updatePassword = async (newPassword) => {
  try {
    const user = firebase.auth().currentUser;
    if (!user) return false;

    await user.updatePassword(newPassword);

    track(AuthEvent.changePassword);
    return true;
  } catch (e) {
    log.error("fb", "update-password", { error: e.toString() });
    return false;
  }
};

// STORAGE

export const uploadFile = async (path, data, contentType) => {
  const ref = firebase.storage().ref(path);
  const snapshot = await ref.put(data, { contentType });
  return snapshot;
};

export const downloadFile = (path) => {
  const ref = firebase.storage().ref(path);
  return ref.getDownloadURL();
};

// HELPERS

export const sendEmail = async (action, email, name) => {
  try {
    const result = await send("ms/fb", { action, email, name });
    return result;
  } catch (e) {
    log.error("fb", "send-email", { error: e.toString() });
  }
};
