export function retry<T>(
  fn: () => Promise<T>,
  retries = 5,
  delay = 1000,
): Promise<T> {
  let retriesLeft = retries;

  return new Promise((resolve, reject) => {
    const attempt = async () => {
      try {
        const result = await fn();
        resolve(result);
      } catch (error) {
        if (retries > 0) {
          setTimeout(() => {
            retriesLeft--;
            attempt();
          }, delay);
        } else {
          reject(error);
        }
      }
    };
    attempt();
  });
}
